import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Crisp } from 'crisp-sdk-web';
import { environment } from 'src/environments/environment';
import { UserApiService } from './service/user-api.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './service/store/language.service';
import { TranslateLoadingService } from './service/store/translate-loading.service';
import {RegionApiService} from "./service/region-api.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private api: UserApiService,
    private apiRegion: RegionApiService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private translateLoading: TranslateLoadingService
  ) {
    if (environment.crispWebsiteID) {
      //Crisp.configure(environment.crispWebsiteID);
      //Crisp.load();
      //$crisp.push(['safe', true]);
    }
  }

  ngOnInit(): void {
    //提前加载语言
    const langList = [
      'en',
      'zh_cn',
      'zh-TW',
      'ko',
      'de',
      'es',
      'fr',
      'ja',
      'pt-PT',
      'ar',
      'it',
      'nl',
      'ru',
    ];
    this.getIpRegion().then(() => {
      const lang = localStorage.getItem('language') || 'en';
      console.log(localStorage.getItem('language'))
      for (let langItem of langList) {
        if (langItem !== lang) {
          this.translate.getTranslation(langItem).subscribe((data) => {
            this.translate.setTranslation(langItem, data);
          });
        }
      }

      // this.languageService.set(lang);
      this.translate.use(lang);

      // 页面初始化的时候检查推广码，如果存在，则调用接口获取推广码的信息
      this.route.queryParams.subscribe((params) => {
        if (params['s']) {
          this.checkAffiliateCode(params['s']);
        }
      });

      // 检查翻译字典的加载状态
      var translateLoading = false;
      this.translateLoading.set(translateLoading);
      this.translate.get('pageHome').subscribe(
          (translation: string) => {
            // 字典已加载完成
            translateLoading = true;
            this.translateLoading.set(translateLoading);
          },
          (error: any) => {
            console.error('Translation load error:', error);
          }
      );
    }
    );



  }

  async getIpRegion() {
    try {
      const r = await this.apiRegion.getIpRegion();
      if (r.data === 'tw' || r.data === 'hk') {
        localStorage.setItem('language','zh-TW');
      } else if (r.data === 'kr') {
        localStorage.setItem('language','ko');
      } else {
        localStorage.setItem('language','en');
      }
    } catch (error) {
      localStorage.setItem('language','en');
    }
    console.log(localStorage.getItem('language'))
  }

  async checkAffiliateCode(code: string) {
    try {
      await this.api.checkAffiliateCode(code);
    } catch (error) {}
  }
}
