<div class="container">
  <app-login-header></app-login-header>
  <app-spinner [loading]="loading">
    <div class="title">{{ "pageLogin.Title1" | translate }} <span>{{ "pageLogin.Title2" | translate }}</span></div>
    <div class="content">
      <!-- <div class="left">
        {{ "pageLogin.WelcomeTip1" | translate }}<br />
        {{ "pageLogin.WelcomeTip2" | translate }}
      </div> -->
      <!-- <div class="space"></div> -->
      <div class="right">
        <!-- <img
          alt="logo"
          width="238"
          height="43"
          routerLink="/"
          src="../../../assets/images/login/logo.png"
        /> -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </app-spinner>
  <app-login-footer></app-login-footer>
</div>
