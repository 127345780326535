// import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core'
// import { TranslateService } from '@ngx-translate/core'

@Component({
    selector: 'app-how-work-panel',
    templateUrl: './how-work-panel.component.html',
    styleUrls: ['./how-work-panel.component.scss'],
})
export class HowWorkPanelComponent implements OnInit {
    points: any = [];
    panelSellingPoint: any;
    isSmall = false;
    constructor() {

    }

    ngOnInit(): void {

    }

    contents = [
        {
            alt: 'Join',
            image: '../../../../assets/images/affiliate/how-work-join.png',
            title: 'pageAffiliateAvert.HowWork-Title1',
            content: 'pageAffiliateAvert.HowWork-Content1',
        },
        {
            alt: 'Promote',
            image: '../../../../assets/images/affiliate/how-work-promote.png',
            title: 'pageAffiliateAvert.HowWork-Title2',
            content: 'pageAffiliateAvert.HowWork-Content2',
        },
        {
            alt: 'Get Commission',
            image: '../../../../assets/images/affiliate/how-work-get-commission.png',
            title: 'pageAffiliateAvert.HowWork-Title3',
            content: 'pageAffiliateAvert.HowWork-Content3',
        },
        
    ]
}