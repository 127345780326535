<div class="point">
    <img [alt]="alt" [src]="image" height="90" />
  
    <div class="description">
      <div class="title">{{ title | translate}}</div>
      <div class="contact">
        <div class="contact-way">{{ contactWay | translate}}</div>
        <a class="contact-url" [href]="'mailto:' + contactUrl" style="text-decoration: none;">{{ contactUrl }}</a>
        <!-- <div class="contact-url">{{ contactUrl }}</div> -->
      </div>
      
  
    </div>
</div>
<div class="space"></div>