import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Crisp } from 'crisp-sdk-web';
import { RegionApiService } from 'src/app/service/region-api.service';
import  { UserService } from 'src/app/service/store/user.service'
import { UserApiService } from 'src/app/service/user-api.service';
import { WorkorderApiService } from 'src/app/service/workorder-api.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateNewTicketModalComponent } from 'src/app/components/help/create-new-ticket-modal/create-new-ticket-modal.component';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss'],
})
export class HelpPageComponent implements OnInit {
  note = '';
  pageHelp: any;
  isSmall: any;
  localStorageLanguage = '';
  ticket: any = [];
  query = '';
  interval: any;

  constructor(
    private translateService: TranslateService,
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver,
    private api: RegionApiService,
    private dialog: MatDialog,
    private userService: UserService,
    private router: Router,
    private apiWorkorder: WorkorderApiService,
    private apiUser: UserApiService

  ) {
    // this.isSmall = this.breakpointObserver.isMatched([
    //   Breakpoints.XSmall,
    //   Breakpoints.Small,
    // ]);
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((r) => {
        this.isSmall = r.matches;
    });
    if (this.isSmall) {
      this.title.setTitle('PremLogin');
    } else {
      this.title.setTitle('Help & Support - PremLogin');
    }

    this.meta.updateTag({
      name: 'description',
      content:
        'Do you have any problem about PremLogin? You can find answers here. Be free to ask our customer service.',
    });

    this.localStorageLanguage = localStorage.getItem('language') || '';

    this.interval = setInterval(() => {
      if (this.self) {
        this.findTicket(this.query);
      }
    }, 60000);
  }

  ngOnInit(): void {
    this.getSelf();
    this.getIpRegion();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  onInputChange(event: any) {
    this.query = event.target.value;
    if (this.self) this.findTicket(this.query);
  }

  chat() {
    //Crisp.chat.open();
  }

  help() {
    window.open('https://help.premlogin.com/');
  }

  region: any;
  async getIpRegion() {
    try {
      const r = await this.api.getIpRegion();
      this.region = r.data;
    } catch (error) {}
  }

  newTicket() {
    if (!this.self) {
      this.router.navigate(['/login']);
      return;
    }
    this.dialog
      .open(CreateNewTicketModalComponent, {
        panelClass: "order-dialog-container",
        width: '726px',
        maxHeight: '726px'
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.findTicket('');
        }
      });
  }

  loading = false;
  async findTicket(query: string) {
    try {
      this.loading = true;
      const r = await this.apiWorkorder.findTicket(query);
      this.ticket = r.data;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  self: any;
  async getSelf() {
    try {
      const r = await this.apiUser.getSelf();
      this.self = r.data;
      this.userService.set(this.self);
      if (r.data) {
        this.self = true;
        this.findTicket('');
      }
    } catch (error) {}
  }
}
