import { Component } from "@angular/core";
import { Router } from '@angular/router'


@Component({
    selector: 'app-partner-panel',
    templateUrl: './partner-panel.component.html',
    styleUrls: ['./partner-panel.component.scss'],
})
export class PartnerPanelComponent{
    constructor(private router: Router){

    }
    cards = [
        {
            alt: 'Influencer',
            image: '../../../../assets/images/about-us/Influencer.png',
            title: 'pageAboutUs.Partner-Title-1',
            content: 'pageAboutUs.Partner-Content-1',
        },
        {
            alt: 'Group Administrator',
            image: '../../../../assets/images/about-us/Group-Administrator.png',
            title: 'pageAboutUs.Partner-Title-2',
            content: 'pageAboutUs.Partner-Content-2'
        },
        {
            alt: 'Blogger',
            image: '../../../../assets/images/about-us/Blogger.png',
            title: 'pageAboutUs.Partner-Title-3',
            content: 'pageAboutUs.Partner-Content-3'
        },
        {
            alt: 'Review Websites',
            image: '../../../../assets/images/about-us/Review-Websites.png',
            title: 'pageAboutUs.Partner-Title-4',
            content: 'pageAboutUs.Partner-Content-4'
        },
        {
            alt: 'Forum Owner/Moderator',
            image: '../../../../assets/images/about-us/Forum.png',
            title: 'pageAboutUs.Partner-Title-5',
            content: 'pageAboutUs.Partner-Content-5'
        },
        {
            alt: 'Coupon Websites',
            image: '../../../../assets/images/about-us/Coupon-Websites.png',
            title: 'pageAboutUs.Partner-Title-6',
            content: 'pageAboutUs.Partner-Content-6'
        }
    ]
}