<div class="container">
  <div class="content">
    <h1 class="mat-headline">
        <span>{{ "pageSubscription.ActiveSubscriptions1" | translate }} </span> {{ "pageSubscription.ActiveSubscriptions2" | translate }}
    </h1>
    <app-spinner
      [loading]="loading || loadingLogin"
      *ngIf="loading || loadingLogin"
    >
    </app-spinner>
    <div class="cards" *ngIf="seats.length > 0">
      <app-bus-seat-card
        *ngFor="let seat of seats"
        [data]="seat"
      ></app-bus-seat-card>
    </div>
    <div class="no-data" *ngIf="seats.length === 0 && !self && !loadingLogin">
      <app-no-data src="../../../assets/images/base/empty-login.webp">{{
        "pageSubscription.NoLogged" | translate
      }}</app-no-data>
      <app-button
        color="#602369"
        fontColor="#FFFFFF"
        height="48px"
        width="250px"
        routerLink="/login"
        >{{ "button.GoLogIn" | translate }}</app-button
      >
    </div>

    <div class="no-data" *ngIf="seats.length === 0 && !loading && self">
      <app-no-data src="../../../assets/images/base/empty.webp">{{
        "pageSubscription.NoSubscription" | translate
      }}</app-no-data>
      <app-button
        color="#602369"
        fontColor="#FFFFFF"
        height="48px"
        width="250px"
        routerLink=""
        >{{ "button.GoToPurchase" | translate }}</app-button
      >
    </div>

    <!-- <div class="banner" *ngIf="normalBanner && self" (click)="gotoAffiliate()">
      <img
        src="../../../assets/images/subscription/banner.webp"
        *ngIf="!isSmall"
        alt="help-img"
      />
      <img
        src="../../../assets/images/subscription/banner-phone.webp"
        *ngIf="isSmall"
        alt="help-img"
      />
    </div> -->
    <!-- <div class="banner" routerLink="/affiliate/dashboard" *ngIf="!normalBanner">
      <img
        src="../../../assets/images/christmas/banner.webp"
        *ngIf="!isSmall"
        alt="banner"
      />
      <img
        src="../../../assets/images/christmas/banner-phone.webp"
        *ngIf="isSmall"
        alt="banner-phone"
      />
    </div> -->
  </div>
</div>
