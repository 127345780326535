<mat-card>
    <div class="close">
        <button mat-dialog-close>
          <img
            alt="del"
            width="35"
            height="35"
            src="../../../../assets/images/base/Icon-del-default.svg"
          />
        </button>
    </div>
    <div class="title">
      {{"pageHeader.BeAffiliateCard-Title" | translate}}
        
    </div>
    <div class="content">
      {{"pageHeader.BeAffiliateCard-Content" | translate}}
        
    </div>
    <div class="pic">
        <img src="../../../../assets/images/page-header/be-affiliate-pic.png"
        width="80%"
        >
    </div>
    <div class="btn">
      <app-button
        (click)="goto()"
        fontColor="#fff"
        width="80%"
        height="50px"
      >
        {{ "pageAffiliateAvert.JoinNow" | translate }}
      </app-button>
        
    </div>
</mat-card>
