<div class="container">
  <div class="content">
    <div class="title">{{ "pageContactUs.Title1" | translate }}<span>{{"pageContactUs.Title2" | translate}}</span></div>
    <div class="panel-container">
      <app-contact-us-panel></app-contact-us-panel>
    </div>
    
    <div class="send-card">
      <app-send-message-card></app-send-message-card>
      
    </div>
  </div>
</div>
