<!-- <div class="container">
  <app-spinner [loading]="loading">
    <div>
      <app-platform-card
        *ngFor="let platform of platforms"
        [data]="platform"
      ></app-platform-card>
    </div>
  </app-spinner>
</div> -->
<mat-tab-group mat-align-tabs="center" [(selectedIndex)]="selectedIndex" id="showPro">
  <mat-tab>
    <ng-template mat-tab-label id="BestSellers">
      {{ "pageHome.BestSellers" | translate }}
    </ng-template>
    <ng-template matTabContent>
      <app-spinner [loading]="loading">
        <div>
          <app-platform-card
            *ngFor="let data of best_sellers"
            [data]="data"
          ></app-platform-card>
        </div>
      </app-spinner>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label id="Streaming">
      {{ "pageHome.Streaming" | translate }}
      
    </ng-template>
    <ng-template matTabContent>
      <app-spinner [loading]="loading">
        <div>
          <app-platform-card
            *ngFor="let data of streaming"
            [data]="data"
          ></app-platform-card>
        </div>
      </app-spinner>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label id="AITools">
      {{"pageHome.AITools" | translate}}
      
    </ng-template>
    <ng-template matTabContent>
      <app-spinner [loading]="loading">
        <div>
          <app-platform-card
            *ngFor="let data of ai_tools"
            [data]="data"
          ></app-platform-card>
        </div>
      </app-spinner>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label id="Music">
      {{"pageHome.Music" | translate}}
      
    </ng-template>
    <ng-template matTabContent>
      <app-spinner [loading]="loading">
        <div>
          <app-platform-card
            *ngFor="let data of music"
            [data]="data"
          ></app-platform-card>
        </div>
      </app-spinner>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label id="Others">
      {{ "pageHome.Others" | translate }}
      
    </ng-template>
    <ng-template matTabContent>
      <app-spinner [loading]="loading">
        <div>
          <app-platform-card
            *ngFor="let data of others"
            [data]="data"
          ></app-platform-card>
        </div>
      </app-spinner>
    </ng-template>
  </mat-tab>

</mat-tab-group>
