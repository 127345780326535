<!-- <app-carousel></app-carousel> -->
 <div class="video-container">
    <video controls src="../../../../assets/video/aboutUs.mp4"></video>
 </div>

<div
  class="carousel-container"
  (swipeleft)="nextSlide()"
  (swiperight)="prevSlide()"
>
    <div class="bg">
        <img src="../../../../assets/images/about-us/bg-1.png" width="100%">
    </div>
    <span (click)="prevSlide()" class="zuojiantou"
        ><img
        alt="arrow"
        width="25"
        height="25"
        src="../../../../assets/images/index/Icon-arrow-card-left.svg"
    /></span>
    <div class="timeline"
    >
        <!-- <div class="bg">
            <img src="/../../../assets/images/about-us/bg-1.png">
        </div> -->
        <div class="container">
            <div 
                class="timeline-year" 
                *ngFor="let data of reviewData; let i = index"
                [ngClass]="{
                active: i === activeIndex,
                prev: i === prevIndex,
                next: i === nextIndex,
                preprev: i === preprevIndex,
                nextnext: i === nextnextIndex,
                }">
                {{ data.year_1 }}<span>{{ data.year_2 }}</span>
            </div>
        </div>
        <div 
            class="container"
            style="
            background: linear-gradient(to right, #b537f2,  #3cb9fc);
            border-radius: 50px;
            "
            >
            <div 
            class="timeline-title" 
            *ngFor="let data of reviewData; let i = index; let last = last"
            [class.no-border-right] = 'last'
            [ngClass]="{
            active: i === activeIndex,
            prev: i === prevIndex,
            next: i === nextIndex,
            preprev: i === preprevIndex,
            nextnext: i === nextnextIndex,
            }">
                {{ data.title | translate}}
            </div>
        </div>

        <div class="container">
            <div 
            class="timeline-description" 
            *ngFor="let data of reviewData; let i = index"
            [ngClass]="{
            active: i === activeIndex,
            prev: i === prevIndex,
            next: i === nextIndex,
            preprev: i === preprevIndex,
            nextnext: i === nextnextIndex,
            }">
            <div class="description">
                {{ data.description | translate}}
            </div>
                
            </div>
        </div>
        
    </div>
    <span (click)="nextSlide()" class="youjiantou"
        ><img
        alt="arrow"
        width="25"
        height="25"
        src="../../../../assets/images/index/Icon-arrow-card-right.svg"
  /></span>
</div>